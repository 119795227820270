import React from "react";

import { BaseComponent } from "../../../utils/BaseComponent";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Endpoint } from "@schneiderpp/salesman-endpoint";
import { ds } from "../../../DataSource";
import { ROUTER_HOME } from "../../home/Router";
import moment from "moment";
import { FormatFloat } from "@schneiderpp/utils-generic";

interface OrderListState {
    datasource: {
        OrderList: ParentStateDatasource<typeof Endpoint.Client.GetClientOrderList>;
    };
}

interface OrderListProps {
    clientId: number;
}

class OrderList extends BaseComponent<RouteComponentProps & OrderListProps, OrderListState> {
    state: OrderListState = {
        datasource: {
            OrderList: DataSourceStateIdle
        }
    };

    private dsOrderList = ds(Endpoint.Client.GetClientOrderList, this, "OrderList", () => this.context);

    componentDidMount() {
        this.getOrderList();
    }

    render() {
        return (
            <>
                <div className="page-tab-content">
                    <div className="page-table">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="w-4 t-left">Data Zamówienia</th>
                                    <th className="w-4">Status</th>
                                    <th className="w-4 t-right">Wartość</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.orderList.map((order) => (
                                    <tr
                                        className="clickable"
                                        key={order.OrderId}
                                        onClick={() =>
                                            this.props.history.push({
                                                pathname: ROUTER_HOME.Orders.Details,
                                                search: `OrderId=${order.OrderId}`
                                            })
                                        }
                                    >
                                        <td className="t-left">{moment(order.OrderDate).format("YYYY-MM-DD")}</td>
                                        <td className="">{order.Status.Name}</td>
                                        <td className="t-right">{FormatFloat(order.Value)} ZŁ</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <Overlay {...this.overlayProps} />
            </>
        );
    }

    get orderList() {
        const dsOrderListData = this.dsOrderList.dataSourceStorage;
        if (dsOrderListData.state === "completed") {
            return dsOrderListData.response.Orders;
        }
        return [];
    }

    get overlayProps(): OverlayProps {
        if (this.dsOrderList.state === "pending" || this.dsOrderList.state === "idle") {
            return {
                show: true,
                title: "Ładowanie..."
            };
        }
        if (this.dsOrderList.state === "error") {
            return {
                show: true,
                title: "coś poszło nie tak",
                description: typeof this.dsOrderList.error === "string" ? this.dsOrderList.error : "",
                children: (
                    <div className="overlay__children">
                        <button className="button light" onClick={() => this.getOrderList()}>
                            spróbuj ponownie
                        </button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    private async getOrderList() {
        await this.dsOrderList.request({
            params: {
                ClientId: this.props.clientId
            }
        });
    }
}

export default withRouter(OrderList);
