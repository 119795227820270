import React from "react";
import { ds } from "../../DataSource";
import { FormValidationError, FormValidationFunction, Form, FormTextField, FormSelect } from "@schneiderpp/utils-forms";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import moment from "moment";
import { BaseComponent } from "../../utils/BaseComponent";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { Endpoint } from "@schneiderpp/salesman-endpoint";
import { ROUTER_HOME } from "../home/Router";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";

interface AddFormFields {
    ClientId: string;
    SupplyDate: string;
}

interface AddState {
    fields: AddFormFields;
    fieldErrors: FormValidationError<AddFormFields>[];
    datasource: {
        Add: ParentStateDatasource<typeof Endpoint.Supply.PostSupplyAdd>;
        ClientList: ParentStateDatasource<typeof Endpoint.Salesman.GetSalesmanClients>;
    };
}

const addFormValidate: FormValidationFunction<AddFormFields> = async (fields) => {
    const errors: Array<FormValidationError<AddFormFields>> = [];
    if (!moment(fields.SupplyDate).isValid()) {
        errors.push({ fieldName: "SupplyDate", code: "InvalidDate" });
    }
    return errors;
};

class Add extends BaseComponent<RouteComponentProps, AddState> {
    state: AddState = {
        fields: {
            ClientId: "",
            SupplyDate: ""
        },
        fieldErrors: [],
        datasource: {
            Add: DataSourceStateIdle,
            ClientList: DataSourceStateIdle
        }
    };

    private dsAdd = ds(Endpoint.Supply.PostSupplyAdd, this, "Add", () => this.context);

    private dsClientList = ds(Endpoint.Salesman.GetSalesmanClients, this, "ClientList", () => this.context);

    private form = new Form<AddFormFields>(this, addFormValidate, (code) => {
        switch (code) {
            case "MustBeNumber":
                return "Musi być numerem";
            case "MustBeHigher":
                return "Musi być większe niz 0";
            case "InvalidDate":
                return "Wprowadzona data jest nieprawidłowa";
            default:
                return code;
        }
    });

    componentDidMount() {
        this.getClientList();
    }

    render() {
        return (
            <>
                <div className="header">
                    <Link to={ROUTER_HOME.Supplies.List} className="button clear no-left-padding">
                        <span className="button__icon">arrow_back_ios</span> wróć do listy zatowarowań
                    </Link>
                </div>
                <div className="page">
                    <div className="page__header">Dodaj nowe zatowarowanie</div>
                    <div className="page-form">
                        <FormTextField config={this.form.getFieldConfig("SupplyDate")} label="Data Zatowarowania" type="date" />
                        <FormSelect
                            config={this.form.getFieldConfig("ClientId")}
                            label="Wybierz Klienta"
                            options={this.clientList.map((c) => ({ value: c.ClientId.toString(), label: `${c.Name} (${c.Company})` }))}
                        />
                        <button className="button align-self-end margin-top-10" onClick={() => this.submit()}>
                            Zatwierdź
                        </button>
                    </div>
                    <Overlay {...this.overlayProps} />
                </div>
            </>
        );
    }

    get clientList() {
        const dsClientListData = this.dsClientList.dataSourceStorage;
        if (dsClientListData.state === "completed") {
            return dsClientListData.response.Clients;
        }
        return [];
    }

    get overlayProps(): OverlayProps {
        if (this.dsAdd.state === "pending" || this.dsClientList.state === "pending" || this.dsClientList.state === "idle") {
            return {
                show: true,
                title: "Ładowanie..."
            };
        }
        if (this.dsAdd.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsAdd.error === "string" ? this.dsAdd.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.dsAdd.resetState()}>spróbuj ponownie</button>
                    </div>
                )
            };
        }
        if (this.dsClientList.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsClientList.error === "string" ? this.dsClientList.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.getClientList()}>spróbuj ponownie</button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    private async getClientList() {
        await this.dsClientList.request({});
        const dsClientListData = this.dsClientList.dataSourceStorage;
        if (dsClientListData.state === "completed" && dsClientListData.response.Clients.length > 0) {
            this.form.setFieldValue("ClientId", () => dsClientListData.response.Clients[0].ClientId.toString());
        }
    }

    private async submit() {
        const isValid = await this.form.validate();
        if (isValid) {
            await this.dsAdd.request({
                data: {
                    SupplyDate: moment(this.state.fields.SupplyDate).toDate(),
                    ClientId: parseInt(this.state.fields.ClientId)
                }
            });
            const dsAdd = this.dsAdd.dataSourceStorage;
            if (dsAdd.state === "completed") {
                this.props.history.push({ pathname: ROUTER_HOME.Supplies.ChangeSupplyElements, search: `SupplyId=${dsAdd.response.SupplyId}` });
            }
        }
    }
}

export default withRouter(Add);
