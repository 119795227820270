import React from "react";
import { ds } from "../../DataSource";
import { BaseComponent } from "../../utils/BaseComponent";
import { FormValidationError, FormValidationFunction, Form, FormTextField, FormSelect } from "@schneiderpp/utils-forms";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Endpoint } from "@schneiderpp/salesman-endpoint";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { ROUTER_HOME } from "../home/Router";

interface AddFormFields {
    Name: string;
    Company: string;
    Email: string;
}

interface AddState {
    fields: AddFormFields;
    fieldErrors: FormValidationError<AddFormFields>[];
    datasource: {
        ClientAdd: ParentStateDatasource<typeof Endpoint.Client.PostClientAdd>;
    };
}

const addFormValidate: FormValidationFunction<AddFormFields> = async fields => {
    const errors: Array<FormValidationError<AddFormFields>> = [];
    if (fields.Name.length < 3) {
        errors.push({ fieldName: "Name", code: "NameTooShort" });
    }
    if (fields.Email.length < 3) {
        errors.push({ fieldName: "Email", code: "EmailTooShort" });
    }
    if (fields.Company.length < 3) {
        errors.push({ fieldName: "Company", code: "CompanyTooShort" });
    }
    return errors;
};

class Add extends BaseComponent<RouteComponentProps, AddState> {
    state: AddState = {
        fields: {
            Name: "",
            Company: "",
            Email: ""
        },
        fieldErrors: [],
        datasource: {
            ClientAdd: DataSourceStateIdle
        }
    };

    private dsClientAdd = ds(Endpoint.Client.PostClientAdd, this, "ClientAdd", () => this.context);

    private form = new Form<AddFormFields>(this, addFormValidate, code => {
        switch (code) {
            case "NameTooShort":
                return "Nazwa za krótka";
            case "EmailTooShort":
                return "Email za krótki";
            case "TempPasswordTooShort":
                return "Hasło za krótkie";
            case "CompanyTooShort":
                return "Nazwa Firmy za krótka";
            default:
                return code;
        }
    });

    render() {
        return (
            <>
                <div className="header">
                    <Link to={ROUTER_HOME.Client.List} className="button clear no-left-padding">
                        <span className="button__icon">arrow_back_ios</span>wróć do listy Klientów
                    </Link>
                </div>
                <div className="page">
                    <div className="page__header">Dodaj nowego klienta</div>
                    <div className="page-form">
                        <FormTextField config={this.form.getFieldConfig("Name")} label="Imię i Nazwisko" />
                        <FormTextField config={this.form.getFieldConfig("Email")} label="Adres email" />
                        <FormTextField config={this.form.getFieldConfig("Company")} label="Nazwa Firmy" />
                        <button className="button align-self-end margin-top-10" onClick={() => this.submit()}>
                            zatwierdź
                        </button>
                    </div>
                    <Overlay {...this.overlayProps} />
                </div>
            </>
        );
    }

    get overlayProps(): OverlayProps {
        const dsClientAdd = this.dsClientAdd.dataSourceStorage;

        if (dsClientAdd.state === "pending") {
            return {
                show: true,
                title: "Ładowanie..."
            };
        }
        if (dsClientAdd.state === "error") {
            return {
                show: true,
                title: "coś poszło nie tak",
                description: typeof this.dsClientAdd.error === "string" ? this.dsClientAdd.error : "",
                children: (
                    <div className="overlay__children">
                        <button className="button light" onClick={() => this.dsClientAdd.resetState()}>spróbuj ponownie</button>
                    </div>
                )
            };
        }
        if (dsClientAdd.state === "completed") {
            return {
                show: true,
                title: "Dodano Klienta",
                children: (
                    <div className="overlay__children">
                        <button
                            className="button light"
                            onClick={() =>
                                this.props.history.push({
                                    pathname: ROUTER_HOME.Client.Detials.About,
                                    search: `ClientId=${dsClientAdd.response.ClientId}`
                                })
                            }
                        >
                            OK
                        </button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    private async submit() {
        const isValid = await this.form.validate();
        if (isValid) {
            await this.dsClientAdd.request({
                data: {
                    Name: this.state.fields.Name,
                    Email: this.state.fields.Email,
                    Company: this.state.fields.Company
                }
            });
        }
    }
}

export default withRouter(Add);
