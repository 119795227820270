import React from "react";
import List from "./List";
import Details from "./Details";
import { Route, Switch, Redirect } from "react-router-dom";

const ROUTER_ORDERS_BASE = "/Orders";

export const ROUTER_ORDERS = {
    Base: ROUTER_ORDERS_BASE,
    List: ROUTER_ORDERS_BASE + "/List",
    Details: ROUTER_ORDERS_BASE + "/Details",
};

const SalesmanRouter: React.FC = () => {
    return (
        <Switch>
            <Route path={ROUTER_ORDERS.List} component={List} />
            <Route path={ROUTER_ORDERS.Details} component={Details} />
            <Redirect path="*" to={ROUTER_ORDERS.List} />
        </Switch>
    );
};

export default SalesmanRouter;
