import React from "react";

import { ds } from "../../DataSource";
import { Link, NavLink } from "react-router-dom";
import { Route, Switch, Redirect, withRouter, RouteComponentProps } from "react-router-dom";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Endpoint } from "@schneiderpp/salesman-endpoint";
import { BaseComponent } from "../../utils/BaseComponent";
import { ROUTER_HOME } from "../home/Router";
import { Overlay, OverlayProps } from "@schneiderpp/utils-components";

interface DetailsState {
    datasource: {
        getDetails: ParentStateDatasource<typeof Endpoint.Salesman.GetSalesmanDetails>;
    };
}

class SalesmanDetails extends BaseComponent<RouteComponentProps, DetailsState> {
    state: DetailsState = {
        datasource: {
            getDetails: DataSourceStateIdle
        }
    };

    private dsDetails = ds(Endpoint.Salesman.GetSalesmanDetails, this, "getDetails", () => this.context);

    componentDidMount() {
        this.getDetails();
    }
    render() {
        const dsDetailsData = this.dsDetails.dataSourceStorage;
        if (dsDetailsData.state === "completed") {
            return (
                <>
                    <div className="page">
                        <div className="page__header">
                            moje konto
                        </div>
                        <div className="page-tab-content">
                            <div className="page-details">
                                <div className="page-details__label">Imię Nazwisko:</div>
                                <div className="page-details__value">{dsDetailsData.response.Name}</div>
                            </div>
                            <div className="page-details">
                                <div className="page-details__label">Adres Email:</div>
                                <div className="page-details__value">{dsDetailsData.response.Email}</div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
        return (
            <div className="page">
                <Overlay {...this.overlayProps} />
            </div>
        );
    }

    get overlayProps(): OverlayProps {
        if (this.dsDetails.state === "pending") {
            return {
                show: true,
                title: "Ładowanie..."
            };
        }
        if (this.dsDetails.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsDetails.error === "string" ? this.dsDetails.error : "",
                children: (
                    <div className="overlay__children">
                        <button className="button light" onClick={() => this.getDetails()}>
                            Spróbuj ponownie
                        </button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    private getDetails() {
        this.dsDetails.request({});
    }
}

export default SalesmanDetails;
