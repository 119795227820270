import React from "react";
import { BaseComponent } from "../../utils/BaseComponent";
import { FormValidationError, Form, FormSelect, FormGroup, FormTextField, FormValidationFunction } from "@schneiderpp/utils-forms";
import { ds } from "../../DataSource";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { Endpoint } from "@schneiderpp/admin-endpoint";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";
import { ROUTER_HOME } from "../home/Router";

interface SupplyElement {
    SupplyElementId: number | undefined;
    Name: string;
    Value: string;
}

interface ChangeSupplyElementsFormFields {
    Elements: SupplyElement[];
}

interface ChangeSupplyElementsState {
    providedSupplyId: number | undefined;
    fields: ChangeSupplyElementsFormFields;
    fieldErrors: FormValidationError<ChangeSupplyElementsFormFields>[];
    datasource: {
        SupplyDetails: ParentStateDatasource<typeof Endpoint.Supply.GetSupplyDetails>;
        SupplyChangeElements: ParentStateDatasource<typeof Endpoint.Supply.PostSupplyChangeElements>;
    };
}

const formValidate: FormValidationFunction<ChangeSupplyElementsFormFields> = async (fields) => {
    const errors: Array<FormValidationError<ChangeSupplyElementsFormFields>> = [];

    for (let i = 0; i < fields.Elements.length; i++) {
        if (fields.Elements[i].Name.length < 3) {
            errors.push({
                fieldName: "Elements",
                code: "ElementNameTooShort",
                index: i,
                arrayFieldName: "Name"
            });
        }

        if (parseInt(fields.Elements[i].Value) < 1) {
            errors.push({
                fieldName: "Elements",
                code: "ElementQuantityMustBePositiveInteger",
                index: i,
                arrayFieldName: "Value"
            });
        }
        if (!Number.isInteger(parseInt(fields.Elements[i].Value))) {
            errors.push({
                fieldName: "Elements",
                code: "MustBeNumber",
                index: i,
                arrayFieldName: "Value"
            });
        }
    }

    return errors;
};

class ChangeSupplyElements extends BaseComponent<RouteComponentProps, ChangeSupplyElementsState> {
    state: ChangeSupplyElementsState = {
        providedSupplyId: undefined,
        fields: {
            Elements: []
        },
        fieldErrors: [],
        datasource: {
            SupplyDetails: DataSourceStateIdle,
            SupplyChangeElements: DataSourceStateIdle
        }
    };

    private dsDetails = ds(Endpoint.Supply.GetSupplyDetails, this, "SupplyDetails", () => this.context);

    private dsChangeElements = ds(Endpoint.Supply.PostSupplyChangeElements, this, "SupplyChangeElements", () => this.context);

    private form = new Form<ChangeSupplyElementsFormFields>(this, formValidate, (code) => code);

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const SupplyId = urlParams.get("SupplyId");
        if (!SupplyId) {
            return;
        }
        const SupplyIdInt = parseInt(SupplyId);
        if (!Number.isInteger(SupplyIdInt)) {
            return;
        }
        this.setState({ providedSupplyId: SupplyIdInt }, () => this.getSupplyDetails());
    }

    render() {
        return (
            <>
                <div className="header">
                    <Link
                        to={{ pathname: ROUTER_HOME.Supplies.Details, search: `SupplyId=${this.state.providedSupplyId}` }}
                        className="button clear no-left-padding"
                    >
                        <span className="button__icon">arrow_back_ios</span>
                        wróć do Detali zatowarowania
                    </Link>
                </div>
                <div className="page">
                    <div className="page__header">Zmień Elementy Zatowarowania</div>
                    <div className="page-form">
                        <FormGroup
                            config={this.form.getFieldConfig("Elements")}
                            element={(c, i) => (
                                <div className="page-form__column">
                                    <FormTextField className="size-1-2" config={c("Name")} label="Nazwa" />
                                    <FormTextField className="size-1-3" config={c("Value")} label="Wartość" onlyInt={true} />
                                    <button onClick={() => this.removeElement(i)} className="button icon margin-top-32">
                                        delete
                                    </button>
                                </div>
                            )}
                        />
                        <button onClick={() => this.addElement()} className="button margin-bottom-10">
                            + Dodaj element
                        </button>
                        <button className="button align-self-end" onClick={() => this.submit()}>
                            Zatwierdź
                        </button>
                    </div>
                    <Overlay {...this.overlayProps} />
                </div>
            </>
        );
    }

    get overlayProps(): OverlayProps {
        if (this.dsChangeElements.state === "pending" || this.dsDetails.state === "idle" || this.dsDetails.state === "pending") {
            return {
                show: true,
                title: "Ładowanie..."
            };
        }
        if (this.dsChangeElements.state === "error") {
            return {
                show: true,
                title: "coś poszło nie tak",
                description: typeof this.dsChangeElements.error === "string" ? this.dsChangeElements.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.dsChangeElements.resetState()}>spróbuj ponownie</button>
                    </div>
                )
            };
        }
        if (this.dsDetails.state === "error") {
            return {
                show: true,
                title: "coś poszło nie tak",
                description: typeof this.dsDetails.error === "string" ? this.dsDetails.error : "",
                children: (
                    <div className="overlay__children">
                        <button onClick={() => this.getSupplyDetails()}>spróbuj ponownie</button>
                    </div>
                )
            };
        }

        return {
            show: false
        };
    }

    private addElement() {
        this.setState((p) => ({
            fields: {
                ...p.fields,
                Elements: [...p.fields.Elements, { SupplyElementId: undefined, Name: "", Quantity: "", Value: "" }]
            }
        }));
    }

    private removeElement(index: number) {
        this.setState((p) => {
            const newElements = [...p.fields.Elements];
            newElements.splice(index, 1);

            return {
                fields: {
                    ...p.fields,
                    Elements: newElements
                }
            };
        });
    }

    private async getSupplyDetails() {
        if (!this.state.providedSupplyId) {
            return;
        }

        await this.dsDetails.request({ params: { SupplyId: this.state.providedSupplyId } });

        const details = this.dsDetails.dataSourceStorage;

        if (details.state === "completed") {
            this.setState((p) => ({
                fields: {
                    ...p.fields,
                    Elements: details.response.Elements.map((e) => ({
                        SupplyElementId: e.SupplyElementId,
                        Name: e.Name,
                        Value: e.Value.toString()
                    }))
                }
            }));
        }
    }

    private async submit() {
        if (!this.state.providedSupplyId) {
            return;
        }
        const isValid = await this.form.validate();
        if (isValid) {
            await this.dsChangeElements.request({
                data: {
                    SupplyId: this.state.providedSupplyId,
                    Elements: this.state.fields.Elements.map((e) => ({
                        SupplyElementId: e.SupplyElementId,
                        Name: e.Name,
                        Quantity: 1,
                        Value: parseInt(e.Value)
                    }))
                }
            });
            if (this.dsChangeElements.dataSourceStorage.state === "completed") {
                this.props.history.push({ pathname: ROUTER_HOME.Supplies.Details, search: `SupplyId=${this.state.providedSupplyId}` });
            }
        }
    }
}

export default withRouter(ChangeSupplyElements);
