import React from "react";
import OrderList from "./OrderList";
import SupplyList from "./SupplyList";
import AgreementList from "./AgreementList";
import About from "./About";
import { Route, Switch, Redirect, RouteComponentProps, Link, NavLink } from "react-router-dom";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Endpoint, GetClientDetails } from "@schneiderpp/admin-endpoint";
import { BaseComponent } from "../../../utils/BaseComponent";
import { ds } from "../../../DataSource";
import { Overlay, OverlayProps } from "@schneiderpp/utils-components";
import { ROUTER_HOME } from "../../home/Router";

const ROUTER_CLIENT_DETAILS_BASE = "/Client/Details";

export const ROUTER_CLIENT_DETAILS = {
    Base: ROUTER_CLIENT_DETAILS_BASE,
    OrderList: ROUTER_CLIENT_DETAILS_BASE + "/OrderList",
    SupplyList: ROUTER_CLIENT_DETAILS_BASE + "/SupplyList",
    AgreementList: ROUTER_CLIENT_DETAILS_BASE + "/AgreementList",
    About: ROUTER_CLIENT_DETAILS_BASE + "/About"
};

interface DetailsState {
    providedClientId: number | undefined;
    datasource: {
        Details: ParentStateDatasource<typeof Endpoint.Client.GetClientDetails>;
    };
}

class ClientDetails extends BaseComponent<RouteComponentProps, DetailsState> {
    state: DetailsState = {
        providedClientId: undefined,
        datasource: {
            Details: DataSourceStateIdle
        }
    };

    private dsDetails = ds(Endpoint.Client.GetClientDetails, this, "Details", () => this.context);

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        const ClientId = urlParams.get("ClientId");
        if (!ClientId) {
            return;
        }
        const ClientIdInt = parseInt(ClientId);
        if (!Number.isInteger(ClientIdInt)) {
            return;
        }
        this.setState({ providedClientId: ClientIdInt }, () => this.getDetails());
    }

    render() {
        if (!this.state.providedClientId) {
            return (
                <div className="page">
                    <div className="page__header">Nie wczytano ClientId</div>
                </div>
            );
        }
        const dsDetailsData = this.dsDetails.dataSourceStorage;
        if (dsDetailsData.state === "completed") {
            return (
                <>
                    <div className="header">
                        <Link to={ROUTER_HOME.Client.List} className="button clear no-left-padding">
                            <span className="button__icon">arrow_back_ios</span> wróć do listy klientów
                        </Link>
                    </div>
                    <div className="page">
                        <div className="page__header">{dsDetailsData.response.Name}</div>
                        <div className="page-links">
                            <NavLink to={{ ...this.props.location, pathname: ROUTER_CLIENT_DETAILS.About }} className="page-links__link">
                                o Kliencie
                            </NavLink>
                            <NavLink
                                to={{ ...this.props.location, pathname: ROUTER_CLIENT_DETAILS.OrderList }}
                                className="page-links__link"
                            >
                                Lista Zamówień Nagród
                            </NavLink>
                            <NavLink
                                to={{ ...this.props.location, pathname: ROUTER_CLIENT_DETAILS.SupplyList }}
                                className="page-links__link"
                            >
                                Lista Zatowarowań
                            </NavLink>

                            <NavLink
                                to={{ ...this.props.location, pathname: ROUTER_CLIENT_DETAILS.AgreementList }}
                                className="page-links__link"
                            >
                                Lista Zgód
                            </NavLink>
                        </div>
                        <Switch>
                            <Route path={ROUTER_CLIENT_DETAILS.About}>
                                <About Details={dsDetailsData.response} />
                            </Route>
                            <Route path={ROUTER_CLIENT_DETAILS.OrderList}>
                                <OrderList clientId={dsDetailsData.response.ClientId} />
                            </Route>
                            <Route path={ROUTER_CLIENT_DETAILS.SupplyList}>
                                <SupplyList clientId={dsDetailsData.response.ClientId} />
                            </Route>
                            <Route path={ROUTER_CLIENT_DETAILS.AgreementList}>
                                <AgreementList clientId={dsDetailsData.response.ClientId} />
                            </Route>
                            <Redirect path="*" to={ROUTER_CLIENT_DETAILS.About} />
                        </Switch>
                        <Overlay {...this.overlayProps} />
                    </div>
                </>
            );
        }
        return (
            <div className="page">
                <Overlay {...this.overlayProps} />
            </div>
        );
    }

    get overlayProps(): OverlayProps {
        if (this.dsDetails.state === "pending") {
            return {
                show: true,
                title: "Ładowanie"
            };
        }
        if (this.dsDetails.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                children: (
                    <div className="overlay__children">
                        <button className="button light" onClick={() => this.getDetails()}>
                            spróbuj ponownie
                        </button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    private getDetails() {
        if (!this.state.providedClientId) {
            return;
        }
        this.dsDetails.request({
            params: { ClientId: this.state.providedClientId }
        });
    }
}

export default ClientDetails;
