import React from "react";
import { BaseComponent } from "../../utils/BaseComponent";

export default class SendInvitation extends BaseComponent {
    render() {
        return(
            <div>
                Send Invitation Client
            </div>
        )
    }
}