import React from "react";
import Details from "./Details";
import { Route, Switch, Redirect } from "react-router-dom";

const ROUTER_SALESMAN_BASE = "/Salesman";

export const ROUTER_SALESMAN = {
    Base: ROUTER_SALESMAN_BASE,
    Details: ROUTER_SALESMAN_BASE + "/Details",
};

const SalesmanRouter: React.FC = () => {
    return (
        <Switch>
            <Route path={ROUTER_SALESMAN.Details} component={Details} />
            <Redirect path="*" to={ROUTER_SALESMAN.Details} />
        </Switch>
    );
};

export default SalesmanRouter;
