import React from "react";

import { BaseComponent } from "../../utils/BaseComponent";
import { ParentStateDatasource, DataSourceStateIdle } from "@schneiderpp/utils-endpoint";
import { Endpoint } from "@schneiderpp/salesman-endpoint";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { ds } from "../../DataSource";
import { OverlayProps, Overlay } from "@schneiderpp/utils-components";
import { ROUTER_HOME } from "../home/Router";
import moment from "moment";
import { FormatFloat } from "@schneiderpp/utils-generic";

interface SuppliesListState {
    datasource: {
        List: ParentStateDatasource<typeof Endpoint.Salesman.GetSalesmanSupplies>;
    };
}

class List extends BaseComponent<RouteComponentProps, SuppliesListState> {
    state: SuppliesListState = {
        datasource: {
            List: DataSourceStateIdle
        }
    };

    private dsList = ds(Endpoint.Salesman.GetSalesmanSupplies, this, "List", () => this.context);

    componentDidMount() {
        this.getList();
    }

    render() {
        return (
            <div className="page">
                <div className="page__header">Lista Zatowarowań</div>
                <div className="page-buttons">
                    <Link to={ROUTER_HOME.Supplies.Add} className="button small">
                        Dodaj zatowarowanie
                    </Link>
                </div>
                <div className="page-table">
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="w-2 t-left">Id Zatowarowania</th>
                                <th className="w-2">Status</th>
                                <th className="w-3">Klient</th>
                                <th className="w-3">Data Zatowarowania</th>
                                <th className="w-2 t-right">Wartość</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.supplyList.map((supply) => (
                                <tr
                                    className="clickable"
                                    key={supply.SupplyId}
                                    onClick={() =>
                                        this.props.history.push({
                                            pathname: ROUTER_HOME.Supplies.Details,
                                            search: `SupplyId=${supply.SupplyId}`
                                        })
                                    }
                                >
                                    <td className="t-left">{supply.SupplyId}</td>
                                    <td>{supply.Status.Name}</td>
                                    <td>{supply.Client.Name}</td>
                                    <td>{moment(supply.SupplyDate).format("YYYY-MM-DD")}</td>
                                    <td className="t-right">{FormatFloat(supply.Value)} ZŁ</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Overlay {...this.overlayProps} />
            </div>
        );
    }

    get supplyList() {
        const dsListData = this.dsList.dataSourceStorage;
        if (dsListData.state === "completed") {
            return dsListData.response.Supplies;
        }
        return [];
    }

    get overlayProps(): OverlayProps {
        if (this.dsList.state === "pending" || this.dsList.state === "idle") {
            return {
                show: true,
                title: "Ładowanie"
            };
        }
        if (this.dsList.state === "error") {
            return {
                show: true,
                title: "Coś poszło nie tak",
                description: typeof this.dsList.error === "string" ? this.dsList.error : "",
                children: (
                    <div className="overlay__children">
                        <button className="button light" onClick={() => this.getList()}>
                            Spróbuj ponownie
                        </button>
                    </div>
                )
            };
        }
        return {
            show: false
        };
    }

    private getList() {
        this.dsList.request({});
    }
}

export default withRouter(List);
